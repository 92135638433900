import React from "react"
import "../delete_account.css"
import { Button, Table } from "antd"
import BreadcrumbRevokeMember from "../breadcrumb/breadcrumb"

const { Column } = Table
const data = [
  {
    key: "1",
    JANcode: "JAN00176241",
    ProductName: "味噌汁",
    PurchaseAmount: 1000,
    condition: "出荷準備",
  },
  {
    key: "2",
    JANcode: "JAN00176241",
    ProductName: "味噌汁",
    PurchaseAmount: 3000,
    condition: "発送済み",
  },
  {
    key: "3",
    JANcode: "JAN00176241",
    ProductName: "おいしいカレー",
    PurchaseAmount: 365,
    condition: "返品処理",
  },
]

const Step3 = ({ setCurrentCpn }) => {
  return (
    <div className="container">
      <BreadcrumbRevokeMember paths={["TOP", "企業情報", "退会手続き未完了"]} />
      <div className="bodyContent" style={{ height: "665px" }}>
        <div
          style={{
            paddingTop: "50px",
            width: "fit-content",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <svg
            width="56"
            height="55"
            viewBox="0 0 56 55"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M25.4019 4.5C26.5566 2.5 29.4434 2.5 30.5981 4.5L49.2176 36.75C50.3723 38.75 48.9289 41.25 46.6195 41.25H9.38046C7.07106 41.25 5.62768 38.75 6.78238 36.75L25.4019 4.5Z"
              fill="#EA6D69"
            />
            <path
              d="M26.725 28.575H29.275L29.95 20.025L30.075 16.3H25.925L26.05 20.025L26.725 28.575ZM28 35.225C29.25 35.225 30.2 34.325 30.2 33.025C30.2 31.7 29.25 30.8 28 30.8C26.75 30.8 25.8 31.7 25.8 33.025C25.8 34.325 26.725 35.225 28 35.225Z"
              fill="white"
            />
          </svg>
        </div>
        <div className="headText">退会手続きを実行できません。</div>
        <div className="bodyText">
          <div>取引中の商品があります。</div>
          <div>取引完了後に再度退会申請を行ってください。</div>
        </div>
        <Table
          dataSource={data}
          pagination={{
            position: ["none", "none"],
          }}
          style={{
            width: "520px",
            height: "160px",
            margin: "0 auto",
            marginTop: "36px",
          }}
        >
          <Column title="JANコード" dataIndex="JANcode" key="JANcode" />
          <Column title="品名" dataIndex="ProductName" key="ProductName" />
          <Column
            title="仕入金額"
            dataIndex="PurchaseAmount"
            key="PurchaseAmount"
          />
          <Column
            title="状態"
            dataIndex="condition"
            key="condition"
            render={(condition) => (
              <>
                {condition === "出荷準備" && (
                  <Button
                    className="btnInTable"
                    style={{
                      backgroundColor: "#ECB427",
                    }}
                  >
                    <span className="textInButton">出荷準備</span>
                  </Button>
                )}
                {condition === "発送済み" && (
                  <Button
                    className="btnInTable"
                    style={{
                      backgroundColor: "#E60020BA",
                    }}
                  >
                    <span className="textInButton">発送済み</span>
                  </Button>
                )}
                {condition === "返品処理" && (
                  <Button
                    className="btnInTable"
                    style={{
                      backgroundColor: "#EC4D43",
                    }}
                  >
                    <span className="textInButton">返品処理</span>
                  </Button>
                )}
              </>
            )}
          />
        </Table>
        <div style={{ textAlign: "center", marginTop: "94px" }}>
          <Button
            type="primary"
            className="funcbtn"
            style={{
              backgroundColor: "#E60020",
            }}
            onClick={() => {
              setCurrentCpn("Step4")
            }}
          >
            企業情報に戻る
          </Button>
        </div>
        <div style={{ textAlign: "center", marginTop: "16px" }}>
          <span className="gotohome">TOPページへ</span>
        </div>
      </div>
    </div>
  )
}

export default Step3
